import React, { useState } from "react";
// To install stripe-js and react-stripe-js, run the following commands:
// npm install @stripe/stripe-js
// npm install @stripe/react-stripe-js
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ onClose, onPaymentMethodAdded }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            // Handle error
            setMessage(error.message);
        } else {
            const paymentMethodId = paymentMethod.id;
            const customerId = sessionStorage.getItem('_id');

            const businessUser = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_business_user_by_id/${customerId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            const businessUserData = await businessUser.json();
            const customer_id = businessUserData.customerData;

            const response = await fetch('https://messaging-api.cypress-software-solutions.com/add-payment-method', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ mongo_id: customerId, payment_method_id: paymentMethodId, default_payment_method: true }),
            });

            if (response.ok) {
                // Payment method successfully added to customer
                setMessage("Payment method added successfully!");
                onClose();
                onPaymentMethodAdded();
            } else {
                // Handle error
                setMessage("Failed to add payment method.");
            }
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <label>
                Name on card
                <br />
                <input
                    type="text"
                    value={name}
                    placeholder="John Doe"
                    onChange={(e) => setName(e.target.value)}
                    style={{ borderRadius: '5px', borderWidth: '0.95px' }}
                />
            </label>
            {/* <label>
        Billing address
        <br />
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          style={{ borderRadius: '5px', borderWidth: '0.95px' }}
        />
      </label> */}
            <label>
                Card details
                <div style={{
                    border: '1px solid gray',
                    borderRadius: '4px',
                    padding: '10px',
                    marginTop: '10px'
                }}>
                    <CardElement options={cardStyle} id="card-element" />
                </div>
            </label>
            <br />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Add Payment Method"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}