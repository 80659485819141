
import { useEffect, useState } from 'react';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency
import { Button, Card, Form, Modal, Table } from 'react-bootstrap';
import Select from 'react-select';
import AddPaymentMethod from '../components/billing/AddPaymentMethod';

export default function ViewGroups() {
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [checkedContacts, setCheckedContacts] = useState({}); // New state for tracking checked contacts
    const [contacts, setContacts] = useState([]);
    const [contactsOptions, setContactsOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newContact, setNewContact] = useState({ first_name: '', phone_number: '' });
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groupName, setGroupName] = useState('');
    const [selectedContact, setSelectedContact] = useState({ first_name: '', phone_number: '' });
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    useEffect(() => {
        const getContacts = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_users/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            // Transform the data into options for the select component
            setContacts(data.contacts);
            const options = data.contacts.map((contact) => ({ value: contact._id, label: contact.first_name, phone_number: contact.phone_number }));
            setContactsOptions(options);
        };

        const getPaymentMethods = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const hasPayment = await response.json(); // Assuming the response is a boolean
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        setShowAddPaymentModal(true); // Open the modal only if there is no payment method
                    }
                } else {
                    console.error('Failed to check payment method status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };


        getContacts();
        getGroups();
        getPaymentMethods();
    }, [])

    const getGroups = async () => {
        setIsLoading(true);
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_groups/${sessionStorage.getItem('_id')}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        console.log(data)
        setGroups(data);
        setIsLoading(false);
    }

    const handleCheck = (groupName, member, isChecked) => {
        setCheckedContacts(prevState => {
            const groupContacts = { ...prevState[groupName] } || {};
            const memberKey = `${member.first_name}-${member.phone_number}`;
            if (isChecked) {
                groupContacts[memberKey] = member;
            } else {
                delete groupContacts[memberKey];
            }
            return { ...prevState, [groupName]: groupContacts };
        });
    };

    const handleDeleteCheckedContacts = async (groupName) => {
        const membersToDelete = checkedContacts[groupName] || {};
        const deletePromises = Object.values(membersToDelete).map(member =>
            handleDeleteContact(groupName, member)
        );
        await Promise.all(deletePromises);
        setCheckedContacts(prevState => ({ ...prevState, [groupName]: {} }));
        // Refresh the groups list to reflect the changes
        await getGroups(); // Assuming getGroups is refactored to be callable here
    };

    const handleAddContactToGroup = async () => {
        if (newContact.first_name && newContact.phone_number || selectedContact.first_name && selectedContact.phone_number) {
            try {
                const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/add_contact_to_group', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        group_name: groupName,
                        contact: {
                            first_name: newContact.first_name || selectedContact.first_name,
                            phone_number: newContact.phone_number || selectedContact.phone_number,
                            account_associated: sessionStorage.getItem('_id')
                        },
                        associated_account: sessionStorage.getItem('_id')
                    })
                });

                if (response.ok) {
                    // Handle success
                    setShowModal(false);
                    setNewContact({ first_name: '', phone_number: '' });
                    getGroups(); // Refresh groups
                } else {
                    // Handle error
                    console.error('Failed to add contact to group');
                }
            } catch (error) {
                console.error('Failed to add contact to group', error);
            }
        }
    };

    const handleDeleteContact = async (groupName, member) => {
        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/delete_contact_from_group', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    group_name: groupName,
                    first_name: member.first_name,
                    phone_number: member.phone_number,
                    associated_account: sessionStorage.getItem('_id')
                })
            });

            if (!response.ok) {
                throw new Error('Failed to delete contact');
            }
            // Optionally, you can handle the response data if needed
        } catch (error) {
            console.error('Failed to delete contact', error);
        }
    };

    const displayAddContactModal = () => {
        return (
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header>
                    <Modal.Title>Add Contact to Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                value={newContact.first_name}
                                onChange={(e) => setNewContact({ ...newContact, first_name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter phone number"
                                value={newContact.phone_number}
                                onChange={(e) => setNewContact({ ...newContact, phone_number: e.target.value })}
                            />
                        </Form.Group>
                        <hr />
                        <Form.Group className="mb-3">
                            <Form.Label>Select Contact</Form.Label>
                            <Select
                                options={contactsOptions}
                                value={selectedGroup}
                                onChange={(selectedOption) => {
                                    setSelectedGroup(selectedOption);
                                    console.log(selectedOption);
                                    setSelectedContact({ first_name: selectedOption.label, phone_number: selectedOption.phone_number });
                                }}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddContactToGroup}>
                        Add Contact
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    };

    const displayGroup = (group) => {
        return Object.entries(group).map(([groupName, groupDetails]) => (
            <Card key={groupName} className="mb-3">
                <Card.Header>
                    <b>{groupName}</b>
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                            const currentDisplay = document.getElementById(`group-${groupName}`).style.display;
                            document.getElementById(`group-${groupName}`).style.display = currentDisplay === 'none' ? 'block' : 'none';
                        }}
                    >
                        {groupDetails.contacts.length} Members
                    </button>{' '}
                    <Button variant="danger" onClick={() => handleDeleteCheckedContacts(groupName)} style={{ float: 'right' }}>Delete Selected</Button>
                    <Button variant="primary" onClick={() => { setShowModal(true); setGroupName(groupName); }} style={{ float: 'right', marginRight: '5px' }}>
                        Add New Contact
                    </Button>
                </Card.Header>
                <Card.Body id={`group-${groupName}`} style={{ display: 'none', padding: 0 }}>
                    <Table striped hover style={{ width: '100%', margin: 0 }}>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Name</th>
                                <th>Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupDetails.contacts.map((member, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={!!(checkedContacts[groupName] && checkedContacts[groupName][`${member.first_name}-${member.phone_number}`])}
                                            onChange={(e) => handleCheck(groupName, member, e.target.checked)}
                                        />
                                    </td>
                                    <td>{member.first_name}</td>
                                    <td>{member.phone_number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        ));
    }

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>View Groups</h1>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                {Object.keys(groups).length > 0 || isLoading === true ? (
                    isLoading ? (
                        <div className="spinner" id="spinner"></div>
                    ) : (
                        Object.entries(groups).map(([groupName, members]) => displayGroup({ [groupName]: members }))
                    )
                ) : (
                    <p>No groups to display.</p>
                )}
                {displayAddContactModal()}
            </div>
        </div>
    )
}