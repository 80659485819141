// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import ContactsOverview from './pages/ContactsOverview';
import AddContact from './pages/AddContacts';
import FindContacts from './pages/FindContacts';
import ReachOutMethod from './pages/ReachoutMethod';
import MessagesOverview from './pages/MessagesOverview';
import MessageDetails from './pages/MessageDetail';
import SentMessages from './pages/SentMessages';
import Billing from './pages/Billing';
import AddGroup from './pages/AddGroup';
import ViewGroups from './pages/ViewGroups';
import RequireSessionId from './RequireSessionId'; // Add this import
import SearchPhoneNumbers from './pages/SearchPhoneNumbers';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EstimatedRevenue from './pages/EstimatedRevenue';

function App() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsUserAuthenticated(!!user);
      setLoading(false); // Set loading to false after getting the user status
    });

    return () => unsubscribe();
  }, []);

  if (loading) { // Render a loading indicator while checking auth state
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* Add the Home route as an authenticated route */}
        <Route
          path="/"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <Home /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/contacts/view-contacts"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <ContactsOverview /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/contacts/add-contact"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <AddContact /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/contacts/find-contacts"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <FindContacts /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/contacts/reach-out"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <ReachOutMethod /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/groups/create-group"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <AddGroup /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/groups/view-groups"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <ViewGroups /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/messaging/overview"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <MessagesOverview /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/messaging/details/:msg_id"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <MessageDetails /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/messaging/sent-messages"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <SentMessages /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/messaging/find-phone-numbers"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <SearchPhoneNumbers /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route
          path="/usage"
          element={
            <RequireSessionId>
              {isUserAuthenticated ? <Billing /> : <Navigate to="/login" replace />}
            </RequireSessionId>
          }
        />
        <Route path="/estimated-revenue" element={<EstimatedRevenue />} />
        {/* Define other routes here */}
      </Routes>
    </Router>
  );
}

export default App;