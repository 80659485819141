import { Card, Col, Row } from "react-bootstrap";
import SidePanel from "../components/SidePanel";
import '../styles/Home.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
    const [totalContacts, setTotalContacts] = useState(0);
    const [messagesSent, setMessagesSent] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/contacts/find-contacts', { replace: true })
        const getContacts = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_users/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setTotalContacts(data.length);
        };
        // getContacts();
    }, []);
    
    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Home</h1>
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Card.Title>Overview</Card.Title>
                                <Card.Text>
                                    Total Contacts: {totalContacts}
                                    <br />
                                    Total Messages Sent: {messagesSent}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Card.Title>Notifications</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Card.Title>Recent Activity</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Card.Title>Performance</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}