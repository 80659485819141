// src/pages/ForgotPassword.js
import React, { useState } from 'react';
import '../styles/ForgotPassword.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase-config';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setMessage('');
        setIsLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('A password reset link has been sent to your email if it is associated with an account.');
        } catch (error) {
            setError('Failed to send password reset email. Please try again.');
        }
        setIsLoading(false);
    };

    return (
        <div className="forgot-password-container">
            <form className="forgot-password-form" onSubmit={handleSubmit}>
                <h1>Forgot Your Password?</h1>
                <p>Enter your email address below and we will send you a link to reset your password.</p>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    required
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Submit'}
                </button>
                {message && <div className="message">{message}</div>}
                {error && <div className="error-message">{error}</div>}
                <Link to="/login">Back to Login</Link>
            </form>
        </div>
    );
}

export default ForgotPassword;