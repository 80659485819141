// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA_w47aeD78WRoA9pi7-ae8qye32R1xaVA",
    authDomain: "messaging-5c71c.firebaseapp.com",
    projectId: "messaging-5c71c",
    storageBucket: "messaging-5c71c.appspot.com",
    messagingSenderId: "1012896901914",
    appId: "1:1012896901914:web:e1fb9a564e019cf2d1daa3",
    measurementId: "G-0VKPKY6EWG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };