// src/pages/SentMessages.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Table, Form, InputGroup } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency

export default function SentMessages() {
    const [searchTerm, setSearchTerm] = useState('');
    // State for sent messages data could be added here
    // const [sentMessages, setSentMessages] = useState([]);

    // Placeholder function for handling the search
    const handleSearch = () => {
        // Logic to perform search based on searchTerm
    };

    // Placeholder data for sent messages
    const sentMessages = [
        { body: 'Hello...', dateSent: '01/01/23', recipients: 'John, Jane, Doe' },
        { body: 'Meeting...', dateSent: '12/12/22', recipients: 'Jane, Doe' },
        // ... more sent messages
    ];

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Sent Messages</h1>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Filter messages"
                                aria-label="Search Bar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Button variant="outline-secondary" onClick={handleSearch}>Search</Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Message Body</th>
                                            <th>Date Sent</th>
                                            <th>Recipients</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sentMessages.map((message, index) => (
                                            <tr key={index}>
                                                <td>{message.body}</td>
                                                <td>{message.dateSent}</td>
                                                <td>{message.recipients}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-between">
                        <Button variant="secondary">Previous Page</Button>
                        <Button variant="secondary">Next Page</Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}