// src/pages/FindContacts.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency
import AddPaymentMethod from '../components/billing/AddPaymentMethod';

export default function FindContacts() {
    const [searchTerm, setSearchTerm] = useState('');
    // State for the search results could be added here
    const [searchResults, setSearchResults] = useState([]);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

    useEffect(() => {
        const getPaymentMethods = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const hasPayment = await response.json(); // Assuming the response is a boolean
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        setShowAddPaymentModal(true); // Open the modal only if there is no payment method
                    }
                } else {
                    console.error('Failed to check payment method status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };
        
        getPaymentMethods();
    }, []);

    // Placeholder function for handling the search
    const handleSearch = async () => {
        setIsLoading(true);
        // Logic to perform search and update searchResults state
        const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'query': searchTerm,
                'max_revenue': 'string',
                'min_revenue': 'string'
            })
        });
        const data = await response.json();
        setSearchResults(data);

        const updateUsageResponse = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/update_business_user_usage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'sender_id': sessionStorage.getItem('_id'),
                'usage': .03
            })
        });

        const updateUsageData = await updateUsageResponse.json();
        console.log(updateUsageData);
        setIsLoading(false);
    };

    // Placeholder function for handling the selection of contacts
    const handleNext = () => {
        // Logic to handle the selected contacts and proceed to the next page
    };

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    const useOverlayTrigger = () => {
        const handleHover = () => {
            setShowAddPaymentModal(true);
        };

        if (!hasPaymentMethod) {
            return (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            Please add a payment method to search for new contacts.
                        </Tooltip>
                    }
                >
                    <span className="d-inline-block" onMouseEnter={handleHover}>
                        <Button
                            variant="primary"
                            disabled={!hasPaymentMethod}
                            style={{ pointerEvents: 'none' }}
                        >
                            Search
                        </Button>
                    </span>
                </OverlayTrigger>
            );
        } else {
            return (
                <Button variant="primary" onClick={handleSearch}>
                    {isLoading ? <div className="spinner"></div> : 'Search'}
                </Button>
            );
        }
    }

    const handleAddPaymentModalShow = () => setShowAddPaymentModal(true);

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Find New Contacts</h1>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Enter search term..."
                                aria-label="Search Bar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            {useOverlayTrigger()}
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Title</th>
                                    <th>Company</th>
                                    <th>Company Website</th>
                                    <th>LinkedIn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Search results would be mapped to rows here */}
                                {searchResults.map((result, index) => (
                                    <tr key={index}>
                                        <td>{result.name}</td>
                                        <td>{result.title}</td>
                                        <td>{result.organization_name}</td>
                                        <td><a href={result.organization_url} target='_blank'>{result.organization_url}</a></td>
                                        <td><a href={result.linkedin_url} target='_blank'>{result.linkedin_url}</a></td>
                                    </tr>
                                ))}
                                {/* More rows would be added here */}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </div>
    );
}