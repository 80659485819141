// src/components/billing/PaymentMethods.js
import React, { useEffect, useState } from 'react';
import { Button, Card } from "react-bootstrap";
import AddPaymentMethod from './AddPaymentMethod';

const PaymentMethods = ({ showModal, handleClose, handleShow }) => {
    const [paymentMethods, setPaymentMethods] = useState(null);

    useEffect(() => {
        const getPaymentMethods = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_payment_method/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setPaymentMethods(data);
        };

        getPaymentMethods();
    }, []);

    const displayPaymentMethods = () => {
        if (!paymentMethods || !paymentMethods.data || paymentMethods.data.length === 0) {
            return (
                <>
                    <br />
                    <Button
                        style={{ backgroundColor: "#ececf1", color: "black", border: "none", fontWeight: "bold", fontSize: "13.5px" }}
                        onClick={handleShow}
                    >
                        Add payment method
                    </Button>
                </>
            )
        } else {
            return (
                <Card className="mb-3" style={{ maxWidth: '18rem' }}>
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <Card.Img
                                src={paymentMethods.data[0].card.image}
                                alt="card"
                                style={{
                                    marginRight: "12px",
                                    maxHeight: "40px",
                                    width: "auto"
                                }}
                            />
                            <Card.Text>
                                <b>••••</b> {paymentMethods.data[0].card.last4}
                                <br />
                                Expires {paymentMethods.data[0].card.exp_month}/{paymentMethods.data[0].card.exp_year}
                            </Card.Text>
                        </div>
                    </Card.Body>
                </Card>
            );
        }
    }

    return (
        <>
            {displayPaymentMethods()}
            <AddPaymentMethod isOpen={showModal} onClose={handleClose} />
        </>
    )
}

export default PaymentMethods;