import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Row, Col, Card } from 'react-bootstrap';
import AddContactForm from '../components/contactsModalTabs/AddContactForm';
import ImportCsv from '../components/contactsModalTabs/ImportCsv';
import GoogleSheetsTab from '../components/contactsModalTabs/GoogleSheetsTab';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css';
import ImportVCard from '../components/contactsModalTabs/ImportVcard';
import AddPaymentMethod from '../components/billing/AddPaymentMethod';

export default function AddContact() {
    const [key, setKey] = useState('addContact');
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    useEffect(() => {
        const getPaymentMethods = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const hasPayment = await response.json(); // Assuming the response is a boolean
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        setShowAddPaymentModal(true); // Open the modal only if there is no payment method
                    }
                } else {
                    console.error('Failed to check payment method status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };

        getPaymentMethods();
    }, [])

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Add New Contact</h1>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                <Row>
                    <Col md={6}>
                        <Tabs
                            id="add-contacts-tabs"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="addContact" title="Add Contact">
                                <AddContactForm />
                            </Tab>
                            <Tab eventKey="importCsv" title="Import CSV">
                                <ImportCsv />
                            </Tab>
                            {/* <Tab eventKey="googleSheets" title="Google Sheets">
                                <Card>
                                    <Card.Body>
                                        <GoogleSheetsTab />
                                    </Card.Body>
                                </Card>
                            </Tab> */}
                            <Tab eventKey="vCard" title="Apple Contacts">
                                <ImportVCard />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </div>
    );
}