// src/pages/MessagesOverview.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Table, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import AddPaymentMethod from '../components/billing/AddPaymentMethod';
import { useNavigate } from 'react-router-dom';

export default function MessagesOverview() {
    const [messageBody, setMessageBody] = useState('');
    const [sendDate, setSendDate] = useState(new Date());
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false); // This is a placeholder for the payment method check
    // Placeholder for contacts options
    const [contactsOptions, setContactsOptions] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [sentMessages, setSentMessages] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isScheduled, setIsScheduled] = useState(false);
    const [scheduledDate, setScheduledDate] = useState(new Date());
    const [scheduledMessages, setScheduledMessages] = useState([]);
    const [totalRecipientCount, setTotalRecipientCount] = useState(0);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [senderPhoneNumber, setSenderPhoneNumber] = useState('');
    const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);
    const navigate = useNavigate(); // Hook for navigation


    useEffect(() => {
        const getContacts = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_users/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('API Data:', data); // Log the raw data

                    setContacts(data.contacts);
                    setGroups(data.groups);

                    const contactOptions = data.contacts.map((contact) => ({
                        value: contact.phone_number,
                        label: contact.first_name
                    }));
                    console.log('Contact Options:', contactOptions); // Log the contact options

                    let groupOptions = [];
                    if (data.groups && data.groups.length > 0) {
                        groupOptions = Object.keys(data.groups[0]).map((key, index) => ({
                            value: index,
                            label: key
                        }));
                        console.log('Group Options:', groupOptions); // Log the group options
                    }

                    setContactsOptions([...contactOptions, ...groupOptions]);
                } else {
                    console.error('Failed to fetch contacts and groups');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };

        const getScheduledMessages = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_scheduled_messages/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.ok) {
                const data = await response.json();
                setScheduledMessages(data);
            } else {
                // Handle error
                console.error('Failed to fetch scheduled messages');
            }
        };

        const getUserPhoneNumbers = async () => {
            const userId = sessionStorage.getItem('_id');
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_user_phone_numbers/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.ok) {
                const data = await response.json();
                setUserPhoneNumbers(data.phone_numbers);
                if (data.phone_numbers.length > 0) {
                    setSenderPhoneNumber(data.phone_numbers[0]); // Set the first number as default
                }
            } else {
                console.error('Failed to fetch user phone numbers');
            }
        };

        const getSentMessages = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_sent_messages/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            // Transform the data into options for the select component
            setSentMessages(data);
        }


        const getPaymentMethods = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const hasPayment = await response.json(); // Assuming the response is a boolean
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        setShowAddPaymentModal(true); // Open the modal only if there is no payment method
                    }
                } else {
                    console.error('Failed to check payment method status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };

        getContacts();
        getSentMessages();
        getPaymentMethods();
        getScheduledMessages();
        getUserPhoneNumbers();
    }, []);

    useEffect(() => {
        const cost = calculateEstimatedCost();
        // You can now use 'cost' for anything else you need in this effect.
    }, [selectedRecipients]);

    useEffect(() => {
        console.log(contactsOptions);
    }, [contactsOptions]);

    const handleSendMessage = async () => {
        setIsSending(true);
        setSuccessMessage('');
        setErrorMessage('');
        let recipientsPhoneNumbers = []; // This will be an array of dictionaries
        for (const recipient of selectedRecipients) {
            if (recipient.isGroup) {
                // If the recipient is a group, add all group members
                const groupName = recipient.label;
                const group = groups.find(g => g.hasOwnProperty(groupName));
                const groupContacts = group[groupName].contacts;
                recipientsPhoneNumbers.push(...groupContacts.map(contact => ({
                    first_name: contact.first_name,
                    account_associated: contact.account_associated,
                    phone_number: contact.phone_number
                })));
            } else {
                // If the recipient is a single contact, add them
                const contact = contacts.find(c => c.phone_number === recipient.value);
                recipientsPhoneNumbers.push({
                    first_name: contact.first_name,
                    account_associated: contact._id,
                    phone_number: contact.phone_number
                });
            }
        }

        const payload = {
            sender: sessionStorage.getItem('_id'),
            from_phone_number: senderPhoneNumber, // Add this line
            message: messageBody,
            contacts: recipientsPhoneNumbers,
            send_at: isScheduled ? scheduledDate.toISOString() : undefined
        };

        const endpoint = isScheduled ? '/api/v1/schedule_message' : '/api/v1/send_message';

        try {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                setSuccessMessage('Message sent successfully!');
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message || 'Failed to send message.');
            }
        } catch (error) {
            setErrorMessage('Network error: Failed to send message.');
        }

        setIsSending(false);
    };

    const handleSelectChange = (selectedOptions) => {
        const updatedRecipients = selectedOptions.map(option => {
            const isGroup = groups.some(group => group.hasOwnProperty(option.label));
            return { ...option, isGroup };
        });
        setSelectedRecipients(updatedRecipients);
    };

    const useOverlayTrigger = () => {
        const handleHover = () => {
            setShowAddPaymentModal(true);
        };

        if (!hasPaymentMethod || userPhoneNumbers.length === 0) {
            return (
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            {!hasPaymentMethod ? 'Please add a payment method to send messages.' : 'Please add a phone number to send messages.'}
                        </Tooltip>
                    }
                >
                    <span className="d-inline-block" onMouseEnter={!hasPaymentMethod ? handleHover : null}>
                        <Button
                            variant="primary"
                            disabled={!hasPaymentMethod || userPhoneNumbers.length === 0}
                            style={{ pointerEvents: 'none' }}
                        >
                            {isSending ? <div className="spinner"></div> : 'Send Message'}
                        </Button>
                    </span>
                </OverlayTrigger>
            );
        } else {
            return (
                <Button
                    variant="primary"
                    onClick={handleSendMessage}
                    disabled={!hasPaymentMethod || userPhoneNumbers.length === 0}
                >
                    {isSending ? <div className="spinner"></div> : 'Send Message'}
                </Button>
            );
        }
    }

    const handleAddPaymentModalShow = () => setShowAddPaymentModal(true);


    function toLocalDateString(utcDateString) {
        const date = new Date(utcDateString + 'Z'); // Adding 'Z' indicates that this is a UTC time
        return date.toLocaleString();
    }

    const calculateEstimatedCost = () => {
        const costPerMessage = 0.05; // Cost per message
        let totalRecipients = 0;

        selectedRecipients.forEach(recipient => {
            if (recipient.isGroup) {
                const group = groups.find(g => g.hasOwnProperty(recipient.label));
                totalRecipients += group[recipient.label].contacts.length;
            } else {
                totalRecipients += 1;
            }
        });

        setTotalRecipientCount(totalRecipients);
        setEstimatedCost((totalRecipients * costPerMessage).toFixed(2));
    };

    const handleRowClick = (msg_id) => {
        navigate(`/messaging/details/${msg_id}`);
    };

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Messages Overview</h1>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="senderPhoneNumber">
                                        <Form.Label>Sender Phone Number:</Form.Label>
                                        <Select
                                            options={userPhoneNumbers.map((number) => ({ value: number, label: number }))}
                                            value={userPhoneNumbers.find((number) => number === senderPhoneNumber) ? { value: senderPhoneNumber, label: senderPhoneNumber } : null}
                                            onChange={(selectedOption) => setSenderPhoneNumber(selectedOption.value)}
                                            className="basic-single"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="recipients">
                                        <Form.Label>Recipients:</Form.Label>
                                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                        <Select
                                            isMulti
                                            options={contactsOptions}
                                            value={selectedRecipients}
                                            onChange={handleSelectChange}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="messageBody">
                                        <Form.Label>New Message:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={messageBody}
                                            onChange={(e) => setMessageBody(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="sendDate">
                                        <Form.Label>Send Date:</Form.Label>{" "}
                                        <DatePicker
                                            selected={scheduledDate}
                                            onChange={(date) => setScheduledDate(date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="form-control"
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Schedule this message"
                                            checked={isScheduled}
                                            onChange={(e) => setIsScheduled(e.target.checked)}
                                            style={{
                                                marginTop: '.5rem',
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="estimatedCost">
                                        <Form.Label>Estimated Cost:</Form.Label>
                                        <div>
                                            ${estimatedCost} for {totalRecipientCount} recipient(s)
                                        </div>
                                    </Form.Group>
                                    {useOverlayTrigger()}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* Sent Messages Table */}
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Card.Title>Sent Messages:</Card.Title>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Message</th>
                                            <th>Date Sent</th>
                                            <th>Recipients</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Sent messages data would be mapped to rows here */}
                                        {sentMessages.map((message, index) => (
                                            <tr key={index} onClick={() => handleRowClick(message.msg_id)} style={{ cursor: 'pointer' }}>
                                                <td>{message.message}</td>
                                                <td>{toLocalDateString(message.date_sent)}</td>
                                                <td>{message.recipients.map(r => r.first_name).join(', ')}</td>
                                            </tr>
                                        ))}
                                        {/* More rows would be added here */}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* Scheduled Messages Table */}
                <Row>
                    <Col>
                        <Card className="card">
                            <Card.Body>
                                <Card.Title>Scheduled Messages:</Card.Title>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Message</th>
                                            <th>Date Scheduled</th>
                                            <th>Recipients</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {scheduledMessages.map((doc, index) => (
                                            doc.scheduled_messages.map((message, msgIndex) => (
                                                <tr key={`${index}_${msgIndex}`}>
                                                    <td>{message.message}</td>
                                                    <td>{toLocalDateString(message.send_at)}</td>
                                                    <td>{(message.recipients || []).map(r => r.first_name).join(', ')}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}