// Add these imports at the top of your file
import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';

const EstimatedRevenue = () => {
    const [users, setUsers] = useState([]);
    const [totalUsage, setTotalUsage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUsageData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/get_all_business_users_usage');
                const data = await response.json();
                setUsers(data.users);
                setTotalUsage(data.total_usage);
            } catch (error) {
                console.error('Failed to fetch usage data:', error);
            }
            setIsLoading(false);
        };

        fetchUsageData();
    }, []);

    return (
        <Container>
            <Card>
                <Card.Header>Estimated Revenue</Card.Header>
                <Card.Body>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            <h2>Total Usage: ${totalUsage.toFixed(2)}</h2>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Usage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>${user.usage.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default EstimatedRevenue;