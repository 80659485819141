// src/components/billing/PricingDetails.js
import React from 'react';

const PricingDetails = () => {
    return (
        <div className="pricing-details">
            <h4
                style={{
                    marginTop: '1rem',
                }}
            >Pricing Information</h4>
            <p>Our services are billed based on your usage:</p>
            <ul>
                <li><strong>Searches:</strong> $0.03 per search</li>
                <li><strong>Messages:</strong> $0.05 per 160 characters in an outgoing message</li>
                <li><strong>Messages:</strong> $0.05 per 160 characters in an outgoing message</li>
            </ul>
        </div>
    );
};

export default PricingDetails;