// src/Login.js
import React, { useEffect, useState } from 'react';
import '../styles/Login.css'; // Make sure to create this CSS file
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../firebase-config';
import { browserLocalPersistence, onAuthStateChanged, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/messaging/overview';


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, redirect to the home page
                navigate('/messaging/overview');
            }
            // No need to handle the else case since we're already on the login page
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true); // Start loading
        try {
            await setPersistence(auth, browserLocalPersistence);
            await signInWithEmailAndPassword(auth, email, password);
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_business_user/${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            if (data._id) {
                sessionStorage.setItem('_id', data._id);
            }
            navigate(from); // Redirect to the initially requested page or default to '/messaging/overview'
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
                <h1 className="login-title">Log In</h1>
                <div className="form-group">
                    <input
                        type="text"
                        id="email"
                        className="login-input"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Email" // Simplified placeholder
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        id="password"
                        className="login-input"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Password" // Simplified placeholder
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                <button className="login-button" type="submit" disabled={isLoading}>
                    {isLoading ? <div className="spinner"></div> : 'Log In'}
                </button>
                <div className="login-links">
                    <Link to="/forgot-password">Forgot your password?</Link>
                    <Link to="/sign-up">Sign Up</Link>
                </div>
            </form>
        </div>
    );
}

export default Login;