import React, { useEffect, useState } from 'react';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal, Button } from 'react-bootstrap';

function ReturnCheckoutIfRegistering({ onClose, onPaymentMethodAdded }) {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("https://messaging-api.cypress-software-solutions.com/create-payment-intent", {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm onClose={onClose} onPaymentMethodAdded={onPaymentMethodAdded} />
                </Elements>
            )}
        </div>
    );
}

function AddPaymentMethod({ isOpen, onClose, onPaymentMethodAdded }) {
    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Add Payment Method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReturnCheckoutIfRegistering onClose={onClose} onPaymentMethodAdded={onPaymentMethodAdded} />
            </Modal.Body>
        </Modal>
    );
}

export default AddPaymentMethod;
