import React, { useState } from 'react';
import '../styles/SignUp.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';
import { auth } from '../firebase-config';
import { browserLocalPersistence, createUserWithEmailAndPassword, setPersistence } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function SignUp() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleFirstNameChange = (event) => setFirstName(event.target.value);
    const handleLastNameChange = (event) => setLastName(event.target.value);
    const handleEmailChange = (event) => setEmail(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);
    const handleConfirmPasswordChange = (event) => setConfirmPassword(event.target.value);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        if (!firstName || !lastName || !email || !password || !confirmPassword) {
            setError('Please fill in all fields.');
            return;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        setIsLoading(true); // Start loading
        try {
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const customerResponse = await fetch('https://messaging-api.cypress-software-solutions.com/create-customer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, name: `${firstName} ${lastName}` }),
            });
            if (customerResponse.ok) {
                const customerData = await customerResponse.json();
                sessionStorage.setItem('customer_id', customerData.customer_id);
    
                // Then register the user, passing the customer_id
                const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/add_business_user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'first_name': firstName,
                        'last_name': lastName,
                        'email': email,
                        'customerData': customerData.customer_id
                    })
                });
                const data = await response.json();
                if (data._id) {
                    sessionStorage.setItem('_id', data._id);
                }
            } else {
                // Handle error
            }
            navigate('/'); // or navigate('/') to go to the home page
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false); // End loading
    };

    // Handlers for input changes and form submission will go here

    return (
        <div className="signup-container">
            <form className="signup-form" onSubmit={handleSubmit}>
                <h1 className="signup-title">Sign Up</h1>
                {/* Input fields and Sign Up button */}
                <input type="text" value={firstName} onChange={handleFirstNameChange} placeholder="First Name" />
                <input type="text" value={lastName} onChange={handleLastNameChange} placeholder="Last Name" />
                <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" />
                <input type="password" value={password} onChange={handlePasswordChange} placeholder="Password" />
                <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder="Confirm Password" />
                <button type="submit" disabled={isLoading} className={isLoading ? 'loading-button' : ''}>
                    {isLoading ? <div className="spinner"></div> : 'Sign Up'}
                </button>
                {/* Error message display */}
                {error && <div className="error-message">{error}</div>}
                {/* Login link */}
                <div className="login-link">
                    Already have an account? <Link to="/login">Log In</Link>
                </div>
            </form>
        </div>
    );
}

export default SignUp;