// src/components/SearchPhoneNumbers.js
import React, { useEffect, useState } from 'react';
import { Card, Button, Table, Row, Col, Modal, Form, Alert } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css';
import AddPaymentMethod from '../components/billing/AddPaymentMethod';

export default function SearchPhoneNumbers() {
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [selectedNumbers, setSelectedNumbers] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const pricePerNumber = 10; // Price per phone number per month
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [pageLoading, setPageLoading] = useState(true);


    useEffect(() => {
        // Check if the user has a payment method on file when the component mounts
        const checkPaymentMethod = async () => {
            const userId = sessionStorage.getItem('_id');
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const hasPaymentMethod = await response.json();
            setHasPaymentMethod(hasPaymentMethod);
        };

        // Fetch available toll-free phone numbers when the component mounts
        const fetchPhoneNumbers = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/search_phone_numbers`, {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json'
                    }
                });
                const data = await response.json();
                setPhoneNumbers(data.available_phone_numbers);
            } catch (error) {
                console.error('Failed to fetch phone numbers:', error);
            }
        };

        setIsLoading(true);
        checkPaymentMethod();
        fetchPhoneNumbers();
        setIsLoading(false);
    }, []);

    const handleCheckboxChange = (phoneNumber) => {
        const newSelectedNumbers = new Set(selectedNumbers);
        if (selectedNumbers.has(phoneNumber)) {
            newSelectedNumbers.delete(phoneNumber);
        } else {
            newSelectedNumbers.add(phoneNumber);
        }
        setSelectedNumbers(newSelectedNumbers);
    };

    const handleBuyNumbers = () => {
        if (!hasPaymentMethod) {
            // If the user doesn't have a payment method, prompt them to add one
            setShowAddPaymentModal(true);
        } else {
            // If the user has a payment method, proceed to show the confirmation modal
            setShowModal(true);
        }
    };

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    const handleConfirmPurchase = async () => {
        setIsConfirmLoading(true);
        const selectedPhoneNumbers = Array.from(selectedNumbers).map(number => number.phone_number);
        const userId = sessionStorage.getItem('_id');
        const postData = JSON.stringify(selectedPhoneNumbers);
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/purchase_phone_numbers?user_id=${userId}`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postData
        });
        if (response.ok) {
            setSelectedNumbers(new Set());
            setSuccess('Phone numbers purchased successfully!');
        } else {
            setError('Failed to purchase phone numbers.');
        }
        setShowModal(false);
        setIsConfirmLoading(false);
    };

    const getTotalCharge = () => {
        return selectedNumbers.size * pricePerNumber;
    };

    const getUnselectedPhoneNumbers = () => {
        return phoneNumbers.filter(number => !selectedNumbers.has(number));
    };

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Phone Numbers</h1>
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header>
                        <Modal.Title>Confirm Purchase</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You have selected the following phone numbers:</p>
                        <ul>
                            {Array.from(selectedNumbers).map((number, index) => (
                                <li key={index}>{number.phone_number}</li>
                            ))}
                        </ul>
                        <p>
                            Total charge: <strong>${getTotalCharge()}</strong> per month
                        </p>
                        <b>By clicking 'Confirm', you authorize us to charge your card on file for the amount stated above.</b>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowModal(false)}
                            style={{
                                backgroundColor: '#808080',
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirmPurchase}>
                            {isConfirmLoading ? <div className="spinner" id="spinner"></div> : 'Confirm'}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                <Row>
                    <Col md={6}>
                        {success && <Alert variant="success">{success}</Alert>}
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Card className="card">
                            <Card.Body>
                                {isLoading && <div className="spinner" id="spinner"></div>}
                                {phoneNumbers.length > 0 && (
                                    <>
                                        <Button onClick={handleBuyNumbers} disabled={selectedNumbers.size === 0}>
                                            Buy Numbers
                                        </Button>
                                        <Table striped bordered hover className="mt-3">
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Phone Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Render selected numbers first */}
                                                {Array.from(selectedNumbers).map((number, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={true}
                                                                onChange={() => handleCheckboxChange(number)}
                                                            />
                                                        </td>
                                                        <td>{number.phone_number}</td>
                                                    </tr>
                                                ))}
                                                {/* Render unselected numbers */}
                                                {getUnselectedPhoneNumbers().map((number, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={selectedNumbers.has(number)}
                                                                onChange={() => handleCheckboxChange(number)}
                                                            />
                                                        </td>
                                                        <td>{number.phone_number}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}