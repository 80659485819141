// src/pages/AddGroup.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Alert, OverlayTrigger, Tooltip, FormCheck, Table } from 'react-bootstrap';
import Papa from 'papaparse';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency
import Select from 'react-select';
import AddPaymentMethod from '../components/billing/AddPaymentMethod';

export default function AddGroup() {
    const [groupName, setGroupName] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [importedContacts, setImportedContacts] = useState([]);
    const [contactsOptions, setContactsOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);


    useEffect(() => {
        const getContacts = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_users/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            // Transform the data into options for the select component
            setContacts(data.contacts);
            const options = data.contacts.map((contact) => ({ value: contact._id, label: contact.first_name }));
            setContactsOptions(options);
        };


        const getPaymentMethods = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const hasPayment = await response.json(); // Assuming the response is a boolean
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        setShowAddPaymentModal(true); // Open the modal only if there is no payment method
                    }
                } else {
                    console.error('Failed to check payment method status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };

        getContacts();
        getPaymentMethods();
    }, []);

    const toggleContactSelection = (contactId) => {
        setSelectedContactIds(prevSelected => {
            if (prevSelected.includes(contactId)) {
                return prevSelected.filter(id => id !== contactId);
            } else {
                return [...prevSelected, contactId];
            }
        });
    };

    // Function to handle "Select All" checkbox
    const toggleSelectAllContacts = (isSelected) => {
        if (isSelected) {
            setSelectedContactIds(contacts.map(contact => contact._id));
        } else {
            setSelectedContactIds([]);
        }
    };

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    const handleSave = async () => {
        setIsLoading(true);
        let recipeientsIds = []
        for (const recipient of selectedRecipients) {
            recipeientsIds.push(recipient.value)
        }

        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/create_group', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'associated_account': sessionStorage.getItem('_id'),
                    'group_name': groupName,
                    'group_ids': selectedContactIds // Use the new state variable
                })
            });

            if (response.ok) {
                // If the response is successful, set a success message
                setError(''); // Clear any previous errors
                setSuccess('Group saved successfully!'); // Assuming you have a state variable for success messages
            } else {
                // If the response is not successful, set an error message
                const errorData = await response.json();
                setError(errorData.message || 'Failed to save group.');
            }
        } catch (error) {
            // If there is an error during fetch, set an error message
            setError('Network error: Failed to save group.');
        }
        setIsLoading(false); // Stop loading
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    setImportedContacts(results.data);
                }
            });
        }
    };

    const handleImport = async () => {
        for (const contact of importedContacts) {
            // You might want to add validation or transformation logic here
            await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/add_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'first_name': contact.groupName,
                    'phone_number': contact.phoneNumber,
                    'account_associated': sessionStorage.getItem('_id')
                })
            });
        }
        // After import, you may want to clear the imported contacts or provide feedback to the user
        setImportedContacts([]);
    };

    const useOverlayTrigger = () => {
        if (!hasPaymentMethod) {
            return (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="tooltip-disabled">
                            Please add a payment method to add new groups.
                        </Tooltip>
                    }
                >
                    <span className="d-inline-block">
                        <Button
                            variant="primary"
                            disabled={!hasPaymentMethod}
                            style={{ pointerEvents: 'none' }}
                        >
                            Save
                        </Button>
                    </span>
                </OverlayTrigger>
            );
        } else {
            return (
                <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                    {isLoading ? <div className="spinner"></div> : 'Save'}
                </Button>
            );
        }
    }

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Create Group</h1>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                <Row>
                    <Col md={6}>
                        {success && <Alert variant="success">{success}</Alert>}
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Card className="card">
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                        <Form.Label>Group Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter group name"
                                            value={groupName}
                                            onChange={(e) => setGroupName(e.target.value)}
                                        />
                                    </Form.Group>
                                    {/* <Form.Group className="mb-3" controlId="recipients">
                                        <Form.Label>Recipients:</Form.Label>
                                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                        <Select
                                            isMulti
                                            options={contactsOptions}
                                            value={selectedRecipients}
                                            onChange={setSelectedRecipients}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group> */}
                                    <Form.Group className="mb-3">
                                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormCheck
                                                            type="checkbox"
                                                            onChange={(e) => toggleSelectAllContacts(e.target.checked)}
                                                            checked={selectedContactIds.length === contacts.length}
                                                        />
                                                    </th>
                                                    <th>Name</th>
                                                    <th>Phone Number</th>
                                                    {/* ... (other columns if necessary) */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contacts.map(contact => (
                                                    <tr key={contact._id}>
                                                        <td>
                                                            <FormCheck
                                                                type="checkbox"
                                                                onChange={() => toggleContactSelection(contact._id)}
                                                                checked={selectedContactIds.includes(contact._id)}
                                                            />
                                                        </td>
                                                        <td>{contact.first_name}</td>
                                                        <td>{contact.phone_number}</td>
                                                        {/* ... (other columns if necessary) */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Form.Group>
                                    {useOverlayTrigger()}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <Col md={6}>
                        <Card className="card">
                            <Card.Body>
                                <Form>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Import Contacts</Form.Label>
                                        <Form.Control type="file" onChange={handleFileChange} />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleImport}>Import</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
            </div>
        </div>
    );
}