import React, { useEffect, useState } from 'react';
import { Button, Form, Alert, Card, Table, InputGroup, FormControl, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../../styles/Home.css';
import importDemoVideo from './import-contacts.mp4';

export default function ImportVCard() {
    const [importedContacts, setImportedContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [importLoading, setImportLoading] = useState(false);
    const [bulkSaveLoading, setBulkSaveLoading] = useState(false); // TODO: Implement this
    const [importError, setImportError] = useState('');
    const [bulkSuccess, setBulkSuccess] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState(new Set());
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        const getPaymentMethods = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setHasPaymentMethod(data);
        };

        getPaymentMethods();
    }, []);

    const overlayTrigger = () => {
        return (
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip id="tooltip-walkthrough">
                        Click for instructions on how to export contacts from your Mac
                    </Tooltip>
                }
            >
                <Button variant="link" onClick={handleShowModal} className="p-0">
                    <svg style={{ marginBottom: '6px' }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
                        <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                    </svg>
                </Button>
            </OverlayTrigger>
        )
    }

    const paymentMethodOverlayTrigger = () => {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Please add a payment method to save new contacts.
                    </Tooltip>
                }
            >
                <span className="d-inline-block">
                    <Button
                        variant="primary"
                        disabled={!hasPaymentMethod}
                        style={{ pointerEvents: 'none' }}
                    >
                        Save
                    </Button>
                </span>
            </OverlayTrigger>
        );
    };

    const handleFileChange = (event) => {
        setImportError('');
        const file = event.target.files[0];
        if (file && file.name.endsWith('.vcf')) {
            setSelectedFile(file);
            convertVCard(file);
        } else {
            setImportError('Please select a valid vCard file (.vcf)');
            setSelectedFile(null);
        }
    };

    const convertVCard = async (file) => {
        setImportLoading(true);
        const formData = new FormData();
        formData.append('vcard_file', file);

        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/convert_vcard_to_json', {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                setImportedContacts(data.contacts);
                setFilteredContacts(data.contacts);
            } else {
                const errorData = await response.json();
                setImportError(errorData.detail || 'Failed to convert vCard.');
            }
        } catch (error) {
            setImportError('Network error: Failed to convert vCard.');
        }
        setImportLoading(false);
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = importedContacts
            .filter(contact =>
                contact.first_name.toLowerCase().includes(value) ||
                contact.phone_number.includes(value)
            )
            .sort((a, b) => {
                // If both are selected or both are unselected, they remain in the same order
                if (selectedContacts.has(a) === selectedContacts.has(b)) {
                    return 0;
                }
                // If 'a' is selected and 'b' is not, 'a' comes first
                if (selectedContacts.has(a)) {
                    return -1;
                }
                // If 'b' is selected and 'a' is not, 'b' comes first
                return 1;
            });
        setFilteredContacts(filtered);
    };

    const handleCheckboxChange = (contact) => {
        const newSelectedContacts = new Set(selectedContacts);
        if (selectedContacts.has(contact)) {
            newSelectedContacts.delete(contact);
        } else {
            newSelectedContacts.add(contact);
        }
        setSelectedContacts(newSelectedContacts);
    };

    const handleBulkSave = async () => {
        setBulkSaveLoading(true);
        // Map over the selectedContacts and add the account_associated field
        const contactsToSave = Array.from(selectedContacts).map(contact => ({
            ...contact,
            account_associated: sessionStorage.getItem('_id')
        }));

        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/add_bulk_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    contacts: contactsToSave
                })
            });

            if (response.ok) {
                setImportError('');
                setImportedContacts([]);
                setSelectedContacts(new Set());
                setBulkSuccess('Selected contacts saved successfully!');
            } else {
                const errorData = await response.json();
                setImportError(errorData.message || 'Failed to save contacts.');
            }
        } catch (error) {
            setImportError('Network error: Failed to save contacts.');
        }
        setBulkSaveLoading(false);
    };

    return (
        <Card className="card">
            <Card.Body>
                {bulkSuccess && <Alert variant="success">{bulkSuccess}</Alert>}
                {importError && <Alert variant="danger">{importError}</Alert>}
                {showModal && (
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header>
                            <Modal.Title>How to Export Contacts from Your Mac</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Follow these steps to export your contacts from the Contacts app on your Mac:</p>
                            <ol>
                                <li>Open the Contacts app.</li>
                                <img src="https://i.imgur.com/EwOVLJ4.png" alt="Open Contacts" className="instructional-image" />
                                <li>Select the contacts you want to export.</li>
                                <img src="https://i.imgur.com/zeWOMyw.png" alt="Select Contacts" className="instructional-image" />
                                <li>Go to the menu bar and select File -{'>'} Export -{'>'} Export vCard...</li>
                                <img src="https://i.imgur.com/AoiCqw9.png" alt="Export Contacts" className="instructional-image" />
                                <li>Choose where to save the file and click Save.</li>
                                <img src="https://i.imgur.com/OQs6c71.png" alt="Save Contacts" className="instructional-image" />
                            </ol>
                            <p>Once you have your vCard file, you can import it here.</p>
                            <video controls className="instructional-video">
                                <source src={importDemoVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                <Form>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label style={{ marginRight: '5px' }}>Import Contacts</Form.Label>
                        {overlayTrigger()}
                        <Form.Control type="file" accept=".vcf" onChange={handleFileChange} />
                    </Form.Group>
                    {importLoading && <div className="spinner" id="spinner"></div>}
                    {filteredContacts.length > 0 && (
                        <>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
                                <FormControl
                                    placeholder="Type to filter..."
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                {hasPaymentMethod ? (
                                    <Button
                                        variant="primary"
                                        onClick={handleBulkSave}
                                        disabled={bulkSaveLoading || selectedContacts.size === 0 || !hasPaymentMethod}
                                    >
                                        {bulkSaveLoading ? 'Saving...' : 'Save Selected'}
                                    </Button>
                                ) : (
                                    paymentMethodOverlayTrigger()
                                )}
                            </InputGroup>
                            <Table striped bordered hover className="mt-3">
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>First Name</th>
                                        <th>Phone Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredContacts.map((contact, index) => (
                                        <tr key={index} style={selectedContacts.has(contact) ? { backgroundColor: '#e8f0fe' } : {}}>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectedContacts.has(contact)}
                                                    onChange={() => handleCheckboxChange(contact)}
                                                />
                                            </td>
                                            <td>{contact.first_name}</td>
                                            <td>{contact.phone_number}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
}