// src/pages/Billing.js
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Billing.css';
import Overview from '../components/billing/Overview';
import PaymentMethods from '../components/billing/PaymentMethods';
import PricingDetails from '../components/billing/PricingDetails';

const Billing = () => {
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [key, setKey] = useState('overview');

    const handlePaymentModalClose = () => setShowPaymentModal(false);
    const handlePaymentModalShow = () => setShowPaymentModal(true);

    return (
        <div className="billing-page">
            <SidePanel />
            <div className="billing-content">
                <h1>Usage</h1>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="controlled-tab-example">
                    <Tab eventKey="overview" title="Overview">
                        <Overview setTabKey={setKey} />
                    </Tab>
                    <Tab eventKey="paymentMethods" title="Payment methods">
                        <PaymentMethods
                            showModal={showPaymentModal}
                            handleClose={handlePaymentModalClose}
                            handleShow={handlePaymentModalShow}
                        />
                    </Tab>
                    <Tab eventKey="pricing" title="Pricing">
                        <PricingDetails />
                    </Tab>
                    {/* <Tab eventKey="billingHistory" title="Billing history">
                        <p>Billing history content goes here...</p>
                    </Tab>
                    <Tab eventKey="preferences" title="Preferences">
                        <p>Preferences content goes here...</p>
                    </Tab> */}
                </Tabs>
            </div>
        </div>
    );
};

export default Billing;