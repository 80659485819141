// src/pages/MessageDetails.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table, ListGroup, Form, Modal } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/Home.css'; // Reusing the same styles for consistency

const ChatModal = ({ show, onHide, chatData, userPhoneNumber, phoneNumberToUse, onMessagesRead }) => {
    const [newMessage, setNewMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);

    useEffect(() => {
        // Only fetch chat history if userPhoneNumber is set and the modal is shown
        if (userPhoneNumber && show) {
            onMessagesRead(userPhoneNumber);
            const fetchChatHistory = async () => {
                setIsLoading(true);
                try {
                    const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_messages/${userPhoneNumber}/${sessionStorage.getItem('_id')}`);
                    const data = await response.json();
                    setChatHistory(data);
                } catch (error) {
                    console.error('Error fetching chat history:', error);
                }
                setIsLoading(false);
            };

            fetchChatHistory();
        }
    }, [userPhoneNumber, show, onMessagesRead]); // Add onMessagesRead to the dependency array


    const fetchChatHistory = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_messages/${userPhoneNumber}/${sessionStorage.getItem('_id')}`);
            const data = await response.json();
            setChatHistory(data);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
        setIsLoading(false);
    };

    const sendMessage = async () => {
        setSendLoading(true);
        if (!newMessage.trim()) return; // Prevent sending empty messages

        const payload = {
            phone_number: userPhoneNumber,
            user_id: sessionStorage.getItem('_id'),
            message: newMessage,
            phone_number_to_use: phoneNumberToUse
        };

        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/send_message_in_response', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Clear the input field after sending the message
            setNewMessage('');

            // Optionally, fetch the updated chat history to show the new message
            await fetchChatHistory();
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setSendLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header>
                <Modal.Title>Chat with {chatData?.first_name}</Modal.Title>
                <Button variant="secondary" onClick={fetchChatHistory}>{isLoading ? <div className="spinner"></div> : 'Refresh'}</Button>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <div className="spinner"></div>}
                <div className="chat-container">
                    {/* Chat messages */}
                    <div className="messages">
                        {/* Render the original message to the right */}
                        {/* Render recipient's responses to the left */}
                        <div className="their-messages">
                            {chatHistory.map((message, index) => (
                                <div key={index} className={`message ${message.sender === 'business' ? 'original-message' : 'response-message'}`}>
                                    <small style={{ color: '#a3a3a3', marginBottom: '5px' }}>
                                        {message.sender === 'business' ? 'You' : chatData?.first_name}
                                    </small>
                                    <br />
                                    {message.message}
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Chat input */}
                    <div className="chat-input">
                        <Form.Control
                            type="text"
                            placeholder="Type a message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <Button
                            onClick={sendMessage}
                            style={{ marginBottom: '10px' }}
                        >
                            {sendLoading ? <div className="spinner"></div> : 'Send'}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default function MessageDetails() {
    const [followUpDate, setFollowUpDate] = useState(new Date());
    const [message, setMessage] = useState(null); // Initialize message state to null
    const navigate = useNavigate(); // To navigate back to the overview page
    const { msg_id } = useParams(); // Extract msg_id from the URL
    const user_id = sessionStorage.getItem('_id'); // Get the user_id from session storage
    const [showChatModal, setShowChatModal] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [userPhoneNumber, setUserPhoneNumber] = useState('');

    useEffect(() => {
        // Function to fetch message details
        const fetchMessageDetails = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_message/${user_id}/${msg_id}`);
                const data = await response.json();
                setMessage(data);
            } catch (error) {
                console.error('Error fetching message details:', error);
                // Handle error, e.g., navigate back, show a message, etc.
            }
        };

        fetchMessageDetails();
    }, [msg_id, user_id]); // Dependency array to re-run the effect if msg_id or user_id changes

    const handleResend = (recipient) => {
        // Logic to resend the message
    };

    const handleScheduleFollowUp = () => {
        // Logic to schedule a follow-up message
    };

    const markMessageAsRead = async (recipientPhoneNumber) => {
        try {
            const payload = {
                user_id: sessionStorage.getItem('_id'),
                recipient_phone_number: recipientPhoneNumber
            };
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/read_message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            // Optionally, handle the response if needed
        } catch (error) {
            console.error('Error marking message as read:', error);
        }
    };

    const handleBackToOverview = () => {
        navigate('/messages-overview'); // The path to your messages overview page
    };

    const handleRowClick = (recipient) => {
        setActiveChat({ ...recipient, originalMessage: message.message });
        setUserPhoneNumber(recipient.phone_number);
        setShowChatModal(true);
    };

    if (!message) {
        return (
            <div className="home-page">
                <SidePanel />
                <div className="home-content">
                    <h1>Message Details</h1>
                    <div className="spinner"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="home-page">
                <SidePanel />
                <div className="home-content">
                    <h1>Message Details</h1>
                    <ChatModal
                        show={showChatModal}
                        onHide={() => setShowChatModal(false)}
                        chatData={activeChat}
                        userPhoneNumber={userPhoneNumber}
                        phoneNumberToUse={message.phone_number_used}
                        onMessagesRead={markMessageAsRead} // Pass the function as a prop
                    />
                    <Card className="card">
                        <Card.Header>
                            <Card.Title
                                style={{
                                    marginBottom: 0
                                }}
                            >Message</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>
                                <b>Date sent:</b> {new Date(message.date_sent).toLocaleString()}
                                <br />
                                <b>Body:</b> {message.message}
                            </p>
                        </Card.Body>
                    </Card>
                    <Card className="card">
                        <Card.Header>
                            <Card.Title
                                style={{
                                    marginBottom: 0
                                }}
                            >Recipients</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Responses</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {message.recipients.map((recipient, index) => (
                                        <tr key={index} onClick={() => handleRowClick(recipient)} style={{ cursor: 'pointer' }}>
                                            <td>{recipient.first_name}</td>
                                            <td>{recipient.phone_number}</td>
                                            <td>{recipient.responses ? recipient.responses.length : 0}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}