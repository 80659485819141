import React, { useState } from 'react';
import './SidePanel.css';

const SidePanel = () => {
  const [showContactsSubList, setShowContactsSubList] = useState(false);
  const [showGroupsSubList, setShowGroupsSubList] = useState(false);
  const [showMessagingSubList, setShowMessagingSubList] = useState(false);
  const [showUsageSubList, setShowUsageSubList] = useState(false);

  const handleClick = (event, toggleFunction) => {
    event.preventDefault();
    toggleFunction(prevState => !prevState);
  }

  return (
    <div className="side-panel">
      {/* <a href="/">Home</a> */}
      {/* <a href="/contacts/find-contacts">Contacts</a> */}
      <a href="/contacts" onClick={(event) => handleClick(event, setShowContactsSubList)}>Contacts</a>
      {showContactsSubList && (
        <div className="sub-list">
          {/* <a href="/contacts/overview">Overview</a> */}
          <a href="/contacts/add-contact">Add Contacts</a>
          <a href="/contacts/find-contacts">Find Contacts</a>
          <a href="/contacts/view-contacts">View Contacts</a>
          {/* <a href="/contacts/reach-out">Reach Out</a> */}
        </div>
      )}
      <a href="/groups" onClick={(event) => handleClick(event, setShowGroupsSubList)}>Groups</a>
      {showGroupsSubList && (
        <div className="sub-list">
          {/* <a href="/contacts/overview">Overview</a> */}
          <a href="/groups/create-group">Create Group</a>
          <a href="/groups/view-groups">View Groups</a>
          {/* <a href="/contacts/reach-out">Reach Out</a> */}
        </div>
      )}
      <a href="/messaging" onClick={(event) => handleClick(event, setShowMessagingSubList)}>Messaging</a>
      {showMessagingSubList && (
        <div className="sub-list">
          <a href="/messaging/overview">Overview</a>
          <a href="/messaging/find-phone-numbers">Phone Numbers</a>
        </div>
      )}
      {/* <a href="/messaging" onClick={(event) => handleClick(event, setShowMessagingSubList)}>Messaging</a>
      {showMessagingSubList && (
        <div className="sub-list">
          <a href="/messaging/overview">Overview</a>
          <a href="/messaging/details">Details</a>
          <a href="/messaging/sent-messages">Sent Messages</a>
        </div>
      )} */}
      <a href="/usage">Billing</a>
    </div>
  );
};

export default SidePanel;