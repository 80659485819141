import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function AddContactForm() {
    const [firstName, setFirstName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    useEffect(() => {
        const getPaymentMethods = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setHasPaymentMethod(data);
        };

        getPaymentMethods();
    }, []);

    const handleSave = async () => {
        if (!hasPaymentMethod) {
            setError('Please add a payment method to save new contacts.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/add_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'first_name': firstName,
                    'phone_number': phoneNumber,
                    'account_associated': sessionStorage.getItem('_id')
                })
            });

            if (response.ok) {
                setError('');
                setSuccess('Contact saved successfully!');
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to save contact.');
            }
        } catch (error) {
            setError('Network error: Failed to save contact.');
        }
        setIsLoading(false);
    };

    const paymentMethodOverlayTrigger = () => {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Please add a payment method to save new contacts.
                    </Tooltip>
                }
            >
                <span className="d-inline-block">
                    <Button
                        variant="primary"
                        disabled={!hasPaymentMethod}
                        style={{ pointerEvents: 'none' }}
                    >
                        Save
                    </Button>
                </span>
            </OverlayTrigger>
        );
    };

    return (
        <Card className="card">
            <Card.Body>
                {success && <Alert variant="success">{success}</Alert>}
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Form.Group>
                    {hasPaymentMethod ? (
                        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                            {isLoading ? 'Saving...' : 'Save'}
                        </Button>
                    ) : (
                        paymentMethodOverlayTrigger()
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
}