// src/pages/ReachOutMethod.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form, ListGroup } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency

export default function ReachOutMethod() {
    const [selectedContacts, setSelectedContacts] = useState([]); // This would be populated from the previous page's selection
    const [reachOutMethod, setReachOutMethod] = useState('');
    const [conditionForAddition, setConditionForAddition] = useState('');

    const handleConfirm = () => {
        // Logic to finalize the reach out method and condition, and initiate the process
    };

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>Reach Out Method</h1>
                <Row>
                    <Col md={6}>
                        <Card className="card">
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <strong>Selected Contacts:</strong>
                                        {selectedContacts.map((contact, index) => (
                                            <div key={index}>{contact.name} ({contact.company})</div>
                                        ))}
                                    </ListGroup.Item>
                                </ListGroup>
                                <Form>
                                    <fieldset>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as="legend" column sm={2}>
                                                Method:
                                            </Form.Label>
                                            <Col sm={10}>
                                                <Form.Check
                                                    type="radio"
                                                    label="LinkedIn Message"
                                                    name="reachOutMethod"
                                                    id="linkedinMessage"
                                                    onChange={() => setReachOutMethod('LinkedIn Message')}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Email"
                                                    name="reachOutMethod"
                                                    id="email"
                                                    onChange={() => setReachOutMethod('Email')}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Text"
                                                    name="reachOutMethod"
                                                    id="text"
                                                    onChange={() => setReachOutMethod('Text')}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </fieldset>
                                    <Form.Group className="mb-3" controlId="formConditionForAddition">
                                        <Form.Label>Condition for Addition:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={conditionForAddition}
                                            onChange={(e) => setConditionForAddition(e.target.value)}
                                        >
                                            <option value="">Select condition...</option>
                                            {/* Options would be populated here */}
                                        </Form.Control>
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleConfirm}>Confirm</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}