// src/pages/ContactsOverview.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Table, Form, InputGroup } from 'react-bootstrap';
import SidePanel from '../components/SidePanel';
import '../styles/Home.css'; // Reusing the same styles for consistency
import { useNavigate } from 'react-router-dom';
import AddPaymentMethod from '../components/billing/AddPaymentMethod';

export default function ContactsOverview() {
    // State for the contacts data could be added here
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);


    useEffect(() => {
        const getContacts = async () => {
            setIsLoading(true);
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_users/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setContacts(data);
            setIsLoading(false);
        };

        const getPaymentMethods = async () => {
            try {
                const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.ok) {
                    const hasPayment = await response.json(); // Assuming the response is a boolean
                    setHasPaymentMethod(hasPayment);
                    if (!hasPayment) {
                        setShowAddPaymentModal(true); // Open the modal only if there is no payment method
                    }
                } else {
                    console.error('Failed to check payment method status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        };

        getContacts();
        getPaymentMethods();
    }, []);

    const updatePaymentMethodStatus = async () => {
        const userId = sessionStorage.getItem('_id');
        const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${userId}`);
        const hasPaymentMethod = await response.json();
        setHasPaymentMethod(hasPaymentMethod);
    };

    return (
        <div className="home-page">
            <SidePanel />
            <div className="home-content">
                <h1>View Contacts</h1>
                <AddPaymentMethod isOpen={showAddPaymentModal} onClose={() => setShowAddPaymentModal(false)} onPaymentMethodAdded={updatePaymentMethodStatus} />
                {/* <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Search by name or phone number"
                                aria-label="Search Bar"
                            />
                            <Button variant="outline-secondary">Search</Button>
                        </InputGroup>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Phone Number</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {/* Contacts data would be mapped to rows here */}
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="2" className="text-center">
                                            <div className="spinner"></div>
                                        </td>
                                    </tr>
                                ) : (
                                    contacts.contacts && contacts.contacts.map((contact, index) => (
                                        <tr key={index}>
                                            <td>{contact.first_name}</td>
                                            <td>{contact.phone_number}</td>
                                            {/* <td>
                                                <Button variant="primary" size="sm">Edit</Button>{' '}
                                                <Button variant="danger" size="sm">Delete</Button>
                                            </td> */}
                                        </tr>
                                    ))
                                )}
                                {/* More rows would be added here */}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="success"
                            onClick={() => navigate('/contacts/add-contact')}
                        >Add New Contact</Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}