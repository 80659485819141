import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './Overview.css';
import IconItem from './IconItem';

const Overview = ({ setTabKey }) => {
    const [usage, setUsage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getUsage = async () => {
            setIsLoading(true);
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_business_user_usage/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setUsage(data);
            setIsLoading(false);
        };

        getUsage();
    }, []);

    const handlePaymentMethodsClick = () => {
        setTabKey('paymentMethods');
    };

    return (
        <div>
            <br />
            <h2>Pay as you go</h2>
            <h6>Pending invoice</h6>
            <h2>{isLoading ? <div className="spinner"></div> : "$" + usage.toFixed(2)}</h2>
            <p>You'll be billed at the end of each calendar month for usage during that month.</p>
            {/* <Button 
                style={{backgroundColor: "#ececf1", color: "black", border: "none", fontWeight: "bold", fontSize: "13.5px"}}
            >Buy credits</Button>{' '}
            <Button 
                style={{backgroundColor: "#ececf1", color: "black", border: "none", fontWeight: "bold", fontSize: "13.5px"}}
            >Cancel billing plan</Button> */}
            <Row className="card-container">
                <Col sm={12} md={6} onClick={handlePaymentMethodsClick}>
                    <div className="icon-text-container">
                        <IconItem
                            d={"M2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7Zm2 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6H4Zm16-2H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2Zm-6 6a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Z"}
                            backgroundColor={"#19c37d"}
                            backgroundImage={"url(https://cdn.openai.com/API/images/icon_bg_green.svg)"}
                        />
                        <div>
                            <b style={{ marginTop: '10px' }}>Payment methods</b>
                            <p>Add or change payment method</p>
                        </div>
                    </div>
                </Col>
                {/* <Col sm={12} md={6}>
                    <div className="icon-text-container">
                        <IconItem 
                            d={"M7 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.828a3 3 0 0 0-.879-2.12l-3.828-3.83A3 3 0 0 0 13.172 2H7Zm5 2H7a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-9h-3a3 3 0 0 1-3-3V4Zm5.586 4H15a1 1 0 0 1-1-1V4.414L17.586 8Z"} 
                            backgroundColor={"#5436da"}
                            backgroundImage={"url(https://cdn.openai.com/API/images/icon_bg_blue.svg)"}
                        />
                        <div>
                            <b style={{marginTop: '10px'}}>Billing history</b>
                            <p>View past and current invoices</p>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div className="icon-text-container">
                        <IconItem 
                            d={"M11.568 3.5a1 1 0 0 0-.863.494l-.811 1.381A3.001 3.001 0 0 1 7.33 6.856l-1.596.013a1 1 0 0 0-.858.501l-.44.761a1 1 0 0 0-.003.992l.792 1.4a3 3 0 0 1 0 2.954l-.792 1.4a1 1 0 0 0 .004.992l.439.76a1 1 0 0 0 .858.502l1.596.013a3 3 0 0 1 2.564 1.48l.811 1.382a1 1 0 0 0 .863.494h.87a1 1 0 0 0 .862-.494l.812-1.381a3 3 0 0 1 2.563-1.481l1.596-.013a1 1 0 0 0 .859-.501l.439-.761a1 1 0 0 0 .004-.992l-.793-1.4a2.999 2.999 0 0 1 0-2.954l.793-1.4a1 1 0 0 0-.004-.992l-.439-.76a1 1 0 0 0-.859-.502l-1.596-.013a3.001 3.001 0 0 1-2.563-1.48L13.3 3.993a1 1 0 0 0-.862-.494h-.87ZM8.98 2.981A3.001 3.001 0 0 1 11.568 1.5h.87a3 3 0 0 1 2.588 1.481l.81 1.382a1 1 0 0 0 .855.494l1.597.013a3.001 3.001 0 0 1 2.575 1.502l.44.76a3 3 0 0 1 .011 2.975l-.792 1.4a1 1 0 0 0 0 .985l.792 1.401a3 3 0 0 1-.012 2.974l-.439.761a3.001 3.001 0 0 1-2.575 1.503l-1.597.012a1 1 0 0 0-.854.494l-.811 1.382a3.001 3.001 0 0 1-2.588 1.481h-.87a3.001 3.001 0 0 1-2.588-1.481l-.811-1.382a1 1 0 0 0-.855-.494l-1.596-.012a3.001 3.001 0 0 1-2.576-1.503l-.439-.76a3 3 0 0 1-.012-2.975l.793-1.4a1 1 0 0 0 0-.985l-.793-1.4a3 3 0 0 1 .012-2.975l.44-.761A3.001 3.001 0 0 1 5.717 4.87l1.596-.013a1 1 0 0 0 .855-.494l.81-1.382Z"} 
                            backgroundColor={"#dd5ce5"}
                            backgroundImage={"url(https://cdn.openai.com/API/images/icon_bg_magenta.svg)"}
                        />
                        <div>
                            <b style={{marginTop: '10px'}}>Preferences</b>
                            <p>Manage billing information</p>
                        </div>
                    </div>
                </Col> */}
            </Row>
        </div>
    );
};

export default Overview;