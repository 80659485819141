import React from 'react';
import './IconItem.css';

const IconItem = ({ d, backgroundColor, backgroundImage }) => {
  const style = {
    backgroundColor: backgroundColor,
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className="icon-item-icon" style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
        <path fillRule="evenodd" d={d} clipRule="evenodd"></path>
      </svg>
    </div>
  );
};

export default IconItem;