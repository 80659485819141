import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for Cypress Messaging</h1>
      <p>Last updated: December 19, 2023</p>

      <p>Welcome to the privacy policy page of Cypress Messaging. Your privacy is critically important to us, and we are committed to protecting it through our compliance with this policy.</p>

      <h2>Information We Collect</h2>
      <p>At Cypress Messaging, we strive to minimize the collection of sensitive user data. We only collect information that is necessary for the functionality of our services. This may include:</p>
      <ul>
        <li>Basic account information such as your name and email address.</li>
        <li>Messages and other content that you send through our service.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>The information we collect is used to provide, maintain, and improve our services. We also use the information to communicate with you about products, services, and promotional offers unless you have opted not to receive such information.</p>

      <h2>Data Storage and Encryption</h2>
      <p>We take the security of your data seriously. To protect your information, we:</p>
      <ul>
        <li>Employ encryption to safeguard your data while in transit and at rest.</li>
        <li>Avoid storing sensitive user data on our servers whenever possible.</li>
        <li>Implement measures to protect against unauthorized access to or use of user data.</li>
      </ul>

      <h2>Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us:</p>
      <ul>
        <li>By email: support@cypress-software-solutions.com</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;