import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from './firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

const RequireSessionId = ({ children }) => {
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in, get the user's ID using the email
                const email = user.email;
                try {
                    const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/get_business_user/${email}`);
                    const data = await response.json();
                    if (data._id) {
                        sessionStorage.setItem('_id', data._id);
                        setUserId(data._id);
                        setIsUserAuthenticated(true);
                    } else {
                        // Handle the case where the user is not found in the database
                        setIsUserAuthenticated(false);
                    }
                } catch (error) {
                    console.error('Error fetching user ID:', error);
                    setIsUserAuthenticated(false);
                }
            } else {
                // User is not signed in
                setIsUserAuthenticated(false);
            }
            setLoading(false); // Set loading to false after getting the user status
        });

        return () => unsubscribe();
    }, []);

    if (loading) { // Render a loading indicator while checking auth state
        return <div>Loading...</div>;
    }

    if (!isUserAuthenticated && !loading) {
        // Redirect to the login page and pass the current location in state
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return children;
};

export default RequireSessionId;