import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Papa from 'papaparse';

export default function ImportCsv() {
    const [importedContacts, setImportedContacts] = useState([]);
    const [importLoading, setImportLoading] = useState(false);
    const [bulkSuccess, setBulkSuccess] = useState('');
    const [bulkError, setBulkError] = useState('');
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    useEffect(() => {
        const getPaymentMethods = async () => {
            const response = await fetch(`https://messaging-api.cypress-software-solutions.com/api/v1/has_payment_method/${sessionStorage.getItem('_id')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setHasPaymentMethod(data);
        };

        getPaymentMethods();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const cleanedContacts = results.data.map(contact => ({
                        ...contact,
                        phone_number: contact.phone_number.replace(/'/g, '') // Remove single quotes
                    }));
                    setImportedContacts(cleanedContacts);
                }
            });
        }
    };

    const handleBulkSave = async () => {
        if (!hasPaymentMethod) {
            setBulkError('Please add a payment method to save new contacts.');
            return;
        }

        setImportLoading(true);
        try {
            const response = await fetch('https://messaging-api.cypress-software-solutions.com/api/v1/add_bulk_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    contacts: importedContacts.map(contact => ({
                        first_name: contact.first_name,
                        phone_number: contact.phone_number,
                        account_associated: sessionStorage.getItem('_id')
                    }))
                })
            });

            if (response.ok) {
                setBulkSuccess('All contacts saved successfully!');
                setImportedContacts([]); // Clear the contacts after saving
            } else {
                const errorData = await response.json();
                setBulkError(errorData.message || 'Failed to save contacts.');
            }
        } catch (error) {
            setBulkError('Network error: Failed to save contacts.');
        }
        setImportLoading(false);
    };

    const infoOverlayTrigger = () => {
        return (
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Make sure your CSV file has the following headers: first_name, phone_number
                    </Tooltip>
                }
            >
                <svg style={{ marginBottom: '3px' }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                </svg>
            </OverlayTrigger>
        );
    }

    const paymentMethodOverlayTrigger = () => {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip-disabled">
                        Please add a payment method to save new contacts.
                    </Tooltip>
                }
            >
                <span className="d-inline-block">
                    <Button
                        variant="primary"
                        disabled={!hasPaymentMethod}
                        style={{ pointerEvents: 'none' }}
                    >
                        Save All
                    </Button>
                </span>
            </OverlayTrigger>
        );
    };

    return (
        <Card className="card">
            <Card.Body>
                {bulkSuccess && <Alert variant="success">{bulkSuccess}</Alert>}
                {bulkError && <Alert variant="danger">{bulkError}</Alert>}
                <Form>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Import Contacts</Form.Label>{" "}
                        {infoOverlayTrigger()}
                        <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    {importedContacts.length > 0 && (
                        <>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Phone Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {importedContacts.map((contact, index) => (
                                        <tr key={index}>
                                            <td>{contact.first_name}</td>
                                            <td>{contact.phone_number}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {hasPaymentMethod ? (
                                <Button variant="primary" onClick={handleBulkSave} disabled={importLoading || !hasPaymentMethod}>
                                    {importLoading ? 'Saving...' : 'Save All'}
                                </Button>
                            ) : (
                                paymentMethodOverlayTrigger()
                            )}
                        </>
                    )}
                </Form>
            </Card.Body>
        </Card>
    );
}